<template>
  <v-row class="fill-height ma-2">
    <v-col cols="12" v-if="userProfile.data().userRole === 'Super Admin' || userProfile.data().userRole === 'Admin'">
      <span class="text-h4 font-weight-light grey--text text--darken-2">
        DashBoard Home Page UI coming soon!
      </span>
    </v-col>
    <v-col cols="12" v-else-if="userProfile.data().groups.length > 0">
      <span class="text-h4 font-weight-light grey--text text--darken-2">
        DashBoard Home Page UI coming soon!
      </span>
    </v-col>
    <v-col cols="12" v-else>
      <span class="text-h4 font-weight-light grey--text text--darken-2">
        Request Admin to assign you to Organization group
      </span>
    </v-col>
  </v-row>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
  name: 'Home',
  computed: {
    ...mapGetters(["userProfile"])
  }
}
</script>
